<template>
  <nav
    class="hidden px-3 lg:flex"
  >
    <a
      class="te-nav-link mr-3 px-1 py-5 text-co-gray-700 hover:text-co-gray-700"
      :href="$host() + '/course'"
    >
      コース紹介
    </a>
    <!-- ドロップダウンメニュー -->
    <div class="dropdown dropdown-hover static mr-3 text-co-gray-700 hover:text-co-gray-700">
      <div
        tabindex="0"
        role="button"
        class="te-nav-link px-1 py-5"
      >
        はじめての方へ
        <i
          class="fa fa-angle-down co-display-11 ml-1"
          aria-hidden="true"
        />
      </div>
      <div
        tabindex="0"
        class="menu dropdown-content inset-x-0 top-[70px] z-[1030] mx-auto grid w-full max-w-screen-xl grid-cols-3 gap-3 overflow-y-scroll rounded-b bg-co-gray-50/[.97] py-3 shadow-[0_6px_12px_rgba(0,0,0,.175)]"
      >
        <a
          :href="$host() + '/about'"
          class="flex basis-4/12 items-center justify-between border border-co-gray-300 p-3 text-co-gray-700 hover:text-co-gray-400"
        >
          <div>
            <span class="mb-3 block text-[1rem]">テックアカデミーとは</span>
            <span class="co-display-14">テックアカデミーについての詳しい内容</span>
          </div>
          <i
            class="fa fa-chevron-circle-right text-[1.25rem]"
            aria-hidden="true"
          />
        </a>
        <a
          :href="$host() + '/diagnoses/top'"
          class="flex basis-4/12 items-center justify-between border border-co-gray-300 p-3 text-co-gray-700 hover:text-co-gray-400"
        >
          <div>
            <span class="mb-3 block text-[1rem]">オススメコース診断</span>
            <span class="co-display-14">質問に答えて最適な受講コースを診断</span>
          </div>
          <i
            class="fa fa-chevron-circle-right text-[1.25rem]"
            aria-hidden="true"
          />
        </a>
        <a
          :href="$host() + '/course/occupation'"
          class="flex basis-4/12 items-center justify-between border border-co-gray-300 p-3 text-co-gray-700 hover:text-co-gray-400"
        >
          <div>
            <span class="mb-3 block text-[1rem]">Web制作の仕事を解説</span>
            <span class="co-display-14">Web制作の流れに沿って対象コースをご案内</span>
          </div>
          <i
            class="fa fa-chevron-circle-right text-[1.25rem]"
            aria-hidden="true"
          />
        </a>
        <a
          :href="$host() + '/price'"
          class="flex basis-4/12 items-center justify-between border border-co-gray-300 p-3 text-co-gray-700 hover:text-co-gray-400"
        >
          <div>
            <span class="mb-3 block text-[1rem]">料金プラン</span>
            <span class="co-display-14">テックアカデミーの受講費用をご紹介</span>
          </div>
          <i
            class="fa fa-chevron-circle-right text-[1.25rem]"
            aria-hidden="true"
          />
        </a>
        <a
          :href="$host() + '/counseling/htmlcss-schedule'"
          class="bot_open flex basis-4/12 items-center justify-between border border-co-gray-300 p-3 text-co-gray-700 hover:text-co-gray-400"
        >
          <div>
            <span class="mb-3 block text-[1rem]">無料相談</span>
            <span class="co-display-14">副業・転職やコース選びなど何でも聞ける個別相談</span>
          </div>
          <i
            class="fa fa-chevron-circle-right text-[1.25rem]"
            aria-hidden="true"
          />
        </a>
      </div>
    </div>
    <a
      class="te-nav-link mr-3 px-1 py-5 text-co-gray-700 hover:text-co-gray-700"
      :href="$host() + '/blog'"
    >
      受講生の声
    </a>
    <a
      class="te-nav-link mr-3 px-1 py-5 text-co-gray-700 hover:text-co-gray-400"
      :href="$host() + '/sidejob'"
    >
      副業支援
    </a>
    <a
      class="te-nav-link mr-4 px-1 py-5 text-co-gray-700 hover:text-co-gray-700"
      :href="$host() + '/jobchange'"
    >
      転職支援
    </a>
    <span class="border-l border-co-gray-300" />
    <a
      class="te-nav-link mx-4 px-1 py-5 text-co-gray-700 hover:text-co-gray-700"
      :href="$host() + '/biz/training'"
    >
      法人向け
    </a>
  </nav>
</template>

<style scoped>
/* リンク下の線 */
.te-nav-link {
  position: relative;
  @media (max-width: 1199.98px) {
    font-size: 0.875rem;
  }
  &::after {
    position: absolute;
    bottom: 0.875rem;
    left: 0;
    width: 100%;
    height: 3px;
    content: '';
    @apply bg-co-primary-500;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(6px);
  }
  &:hover,
  &:focus {
    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
